import { useEffect, useState } from "react";
import { Skill as SkillInterface } from "../tools/interfaces";

export default function Stack({
  loggedIn,
  skillsUsed,
  updateStack,
}: {
  loggedIn: boolean | null;
  skillsUsed: string[] | [];
  updateStack: ((skill: string[]) => void) | null;
}) {
  const [skillsAvailable, setSkillsAvailable] = useState<
    [SkillInterface] | null
  >(null);
  const [projectStack, setProjectStack] = useState<string[]>([]);

  async function fetchSkillsAvailable() {
    try {
      const response = await fetch("https://zakharii.com/api_endpoints/skills/");
      const data = await response.json();
      setSkillsAvailable(data);
    } catch (error) {

    }
  }

  const togleSkill = (skillId: string) => {
    projectStack.includes(skillId)
      ? setProjectStack(
          [...projectStack].filter((prStack: string) => prStack !== skillId)
        )
      : setProjectStack([...projectStack, skillId]);
  };

  useEffect(() => {
      setProjectStack(skillsUsed);
  }, [skillsAvailable]);

  useEffect(() => {
    fetchSkillsAvailable();
    if (updateStack) updateStack(projectStack);
  }, [projectStack]);

  const usedSkillStyle = { backgroundColor: "blue" };
  const unUsedSkillStyle = { backgroundColor: "grey" };

  return !skillsAvailable || !projectStack ? null : (
    <div className="Stack">
      {!loggedIn ? (
        <div className="stackIcons">
          {skillsAvailable?.map((skill) => {
            return !projectStack?.includes(skill.id) ? null : (
              <img
                className="unAuthorized"
                key={skill.id}
                src={"data:image;base64," + skill.icon}
                alt={skill.name}
              />
            );
          })}
        </div>
      ) : (
        <ul className="editStack">
          {skillsAvailable?.map((skill) => {
            return (
              <li
                onClick={() => togleSkill(skill.id)}
                style={
                  projectStack.includes(skill.id)
                    ? usedSkillStyle
                    : unUsedSkillStyle
                }
                key={skill.id}
              >
                {skill.name}
              </li>
            );
          })}
        </ul>
      )}{" "}
    </div>
  );
}
