import Chapter from "./Chapter";

export default function Languages() {
  return (
    <div className="Languages">
      <Chapter header="Languages" />
      <ul>
        <li id="polish">
          <h3>Polish B1</h3>
          <img
            className="flag"
            src={require("../style/icons/polish-flag.png")}
          />
        </li>
        <li id="german">
          <h3>German B2</h3>
          <img
            className="flag"
            src={require("../style/icons/german-flag.jpg")}
          />
        </li>
        <li id="english">
          <h3>English C1</h3>
          <img
            className="flag"
            src={require("../style/icons/british-flag.png")}
          />
        </li>
        <li id="ukrainian">
          <h3>Ukrainian C2</h3>
          <img
            className="flag"
            src={require("../style/icons/ukrainian-flag.png")}
          />
        </li>
      </ul>
    </div>
  );
}
