import Visits from "./Visits";
import LoginAttempts from "./LoginAttempts";
import Messages from "./Messages";

export default function Admin() {
    return (
        <div className="Admin">
            <form action="https://zakharii.com/api_endpoints/login/" method="POST">
                <input type="hidden" name="action" value="log_out" />
                <input className="submitBtn" style={{ color: "grey" }} type="submit" value="Log out" />
            </form>
            <Visits />
            <LoginAttempts />
            <Messages />
        </div>
    )
}