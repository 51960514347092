import { useState, useEffect } from "react";
import bowser from 'bowser';
import { Visit as VisitInterface } from "../tools/interfaces";
import DeleteItem from "./DeleteItem";
import Chapter from "./Chapter";

export default function Visits() {
  const [visits, setVisits] = useState<VisitInterface[]>();
  const [showVisit, setShowVisit] = useState<string>("");

  const getVisits = async () => {
    const request = await fetch("https://zakharii.com/api_endpoints/get_info/", {
      method: "GET",
      credentials: "include",
    });
    const result = await request.json();
    setVisits(result?.visits.reverse());
  };

  const markAsRead = async (id: string, i: number) => {
    showVisit === id ? setShowVisit("") : setShowVisit(id);
    if (visits && visits[i].seen === "1") return;
    const form = new FormData();
    form.append("id", id);
    form.append("seen", "1");
    const request = await fetch("https://zakharii.com/api_endpoints/get_info/", {
      method: "POST",
      body: form,
    });
    if (!request.ok) {
      throw new Error(request.statusText);
    } else {
      if (visits)
        setVisits([
          ...visits.slice(0, i),
          { ...visits[i], seen: "1" },
          ...visits.slice(i + 1),
        ]);
    }
  };

  const getDeviceInfo = (agent: string) =>{
    const userAgent = bowser.getParser(agent);
    return {
      browser: userAgent.getBrowser(),
      os: userAgent.getOS(),
      platform: userAgent.getPlatformType()
    }
  }

  useEffect(() => {
    getVisits();
  }, []);
  
  return !visits ? null : (
    <div>
      <Chapter header="Visits"/>
      <h3>New:{visits.filter((vis) => vis.seen === "0").length}</h3>

      {visits.map((visit, i) => {
        const device = getDeviceInfo(visit.os);
        return (
          <div
            style={{ backgroundColor: visit.seen === "0" ? "red" : "grey" }}
            key={visit.id}
          >
            <h2 onClick={() => markAsRead(visit.id, i)}>
              <span>{visit?.geolocation?.city?.name} {visit.timestamp}</span>
            </h2>
            {visit.id !== showVisit ? null : (
              <ul>
                <li>Referrer: {visit.referrer}</li>
                <li>Languages: {visit.languages}</li>
                <li>Visited times: {visit.localstorage}</li>
                <li>IP: {visit.geolocation?.ip}</li>
                <li>Continent: {visit.geolocation?.continent?.name}</li>
                <li>Country: {visit.geolocation?.country?.name}</li>
                <li>State: {visit.geolocation?.state?.name}</li>
                <li>City: {visit.geolocation?.city?.name}</li>
                <li>Latitude: {visit.geolocation?.location?.latitude}</li>
                <li>Longitude: {visit.geolocation?.location?.longitude}</li>
                <li>Platform: {device.platform}</li>
                <li>OS: {device.os.name}</li>
                <li>Browser: {`${device.browser.name} ${device.browser.version}`}</li>
                <li>Screen: {visit.screen}</li>
                <li><DeleteItem id={visit.id}
                    category="visits"
                    updateList={()=>{return}}/></li>
                <br />
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
}
