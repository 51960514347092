import { useEffect, useState } from "react";

export default function Tracker({ loggedIn }: { loggedIn: boolean | null }) {
  const [data, setData] = useState<{ [key: string]: string | null }>({
    languages: null,
    os: null,
    referrer: null,
    screen: null,
    geolocation: null,
    localstorage: null,
  });
  const [submited, setSubmited] = useState(false);

  const trackerForm = new FormData();

  const getGeolocation = async () => {
    const link = await fetch(
      "https://api.geoapify.com/v1/ipinfo?apiKey=ee45811af99e47ee817d7d868b4dca8b"
    );
    const response = await link.json();
    return response;
  };

  const getLocalStorage = () => {
    const storageData = localStorage.getItem("visitsCount");
    const visitsCount = storageData ? JSON.parse(storageData) : 0;
    if (!submited) localStorage.setItem("visitsCount", visitsCount + 1);
    return visitsCount;
  };

  const handleSubmit = async () => {
    const request = await fetch("https://zakharii.com/api_endpoints/get_info/", {
      method: "POST",
      body: trackerForm,
    });
    if (!request.ok) throw new Error(request.statusText);
    return request;
  };

  const prepearData = async () => {
    if (submited) return;
    const visitedTimes = getLocalStorage();
    const location = await getGeolocation();
    setData({
      ...data,
      languages: navigator.languages.join(","),
      os: navigator.userAgent,
      referrer: document.referrer ? document.referrer : "Not referred",
      screen: `
        Width: ${window.screen.width};
        Height: ${window.screen.height};
        Colordepth: ${window.screen.colorDepth || window.screen.pixelDepth};
        `,
      localstorage: visitedTimes,
      geolocation: location,
    });
  };

  useEffect(() => {
    prepearData();
  }, []);

  useEffect(() => {
    if (data.languages) trackerForm.append("languages", data.languages);
    if (data.os) trackerForm.append("os", data.os);
    if (data.referrer) trackerForm.append("referrer", data.referrer);
    if (data.screen) trackerForm.append("screen", data.screen);
    if (data.geolocation)
      trackerForm.append("geolocation", JSON.stringify(data.geolocation));
    if (data.localstorage)
      trackerForm.append("localstorage", data.localstorage);
  }, [data]);

  useEffect(() => {
    if (!loggedIn && !submited && data.geolocation) {
      handleSubmit();
      setSubmited(true);
    }
  }, [data, submited]);

  return null;
}
