import { Navigate } from "react-router-dom";
import Chapter from "./Chapter";
export default function LoginPage({ loggedIn }: { loggedIn: boolean | null }) {
  return (
    <>
      {loggedIn ? (
        <Navigate replace to="/admin" />
      ) : (
        <div className="Login">
          <Chapter header="Authentication"/>
          <form action="https://zakharii.com/api_endpoints/login/" method="POST">
            <input className="name" placeholder="Name" name="name" />
            <input className="pswd" placeholder="Password" name="password" type="password" />
            <input className="submitBtn" type="submit" value="Log in" />
          </form>
        </div>
      )}
    </>
  );
}
