import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

export default function DeleteItem({
  id,
  category,
  updateList,
}: {
  id: string;
  category: string;
  updateList: (() => void) | null;
}) {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDelete = () => {
    fetch(`https://zakharii.com/api_endpoints/delete_item/?${category}&${id}`, {
      method: "DELETE",
      credentials: "include"
    }).then((data) => {
      if (data.status === 200 && updateList) updateList();
    });
  };

  return (
    <div className="DeleteItem">
      {!showConfirm ? null : (
        <div className="confirmDelete">
          <div onClick={() => setShowConfirm(false)}>Cancel</div>
          <div onClick={() => handleDelete()}>Delete item?</div>
        </div>
      )}
      <AiFillDelete onClick={() => setShowConfirm(true)} />
    </div>
  );
}
