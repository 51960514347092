import Chapter from "./Chapter";
import { Message as MessageInterface } from "../tools/interfaces";
import { useEffect, useState } from "react";
export default function Messages() {

  const [msgs, setMsgs] = useState<MessageInterface[]>();
  const [showMsg, setShowMsg] = useState("");


  const getMessages = async () => {
    const request = await fetch(
      "https://zakharii.com/api_endpoints/get_info/",
      {
        method: "GET",
        credentials: "include",
      }
    );
    const result = await request.json();
    setMsgs(result?.messages.reverse());
    console.log(result?.messages.reverse());
  };

  const markAsRead = async (id: string, i: number) => {
    showMsg === id ? setShowMsg("") : setShowMsg(id);
    if (msgs && msgs[i].seen === "1") return;
    const form = new FormData();
    form.append("id", id);
    form.append("seen", "1");
    const request = await fetch("https://zakharii.com/api_endpoints/contact/", {
      method: "POST",
      body: form,
    });
    if (!request.ok) {
      throw new Error(request.statusText);
    } else {
      if (msgs)
        setMsgs([
          ...msgs.slice(0, i),
          { ...msgs[i], seen: "1" },
          ...msgs.slice(i + 1),
        ]);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);
  return !msgs ? null : (
    <div>
      <Chapter header="Messages" />
      {msgs.map((message, i) => {
        return (<div style={{ backgroundColor: message.seen.toString() === "0" ? "red" : "grey" }}>
            <h1 onClick={() => markAsRead(message.id, i)}>{message.name}</h1>
            <h3>New:{msgs.filter((m) => m.seen.toString() === "0").length}</h3>
            {message.id !== showMsg ? null :(<ul>
                <li>{message.email}</li>
                <li>{message.name}</li>
                <li>{message.message}</li>
                <li>{message.timestamp}</li>
            </ul>)}
        </div>);
      })}
    </div>
  );
}
