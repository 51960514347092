import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header({ loggedIn }: { loggedIn: boolean | null }) {
  const location = useLocation();
  const [activeClass, setActiveClass] = useState<string>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isAProjectRoute, setIsAProjectRoute] = useState<boolean>(false);

  useEffect(() => {
    const path = location.pathname.slice(1);
    setActiveClass(path);

    const hideHeader = () => {
      if (path.includes('projects') && /projects(\/.+)$/.test(path)) {
        setIsAProjectRoute(true);
      } else {
        setIsAProjectRoute(false);
      }
    };

    hideHeader();
    window.addEventListener('popstate', hideHeader);

    return () => {
      window.removeEventListener('popstate', hideHeader);
    };
  }, [location.pathname]);

  return isAProjectRoute ? null : (
    <div className="Header">
      <Link to="/" className="brand">
        <img className="avatar" src={require("../style/icons/ava.jpg")} />
        <h2>Z. Husar</h2>
      </Link>
      <div
        className={showMenu ? "toggleBtnOn" : "toggleBtnOff"}
        onClick={() => setShowMenu(!showMenu)}
      >

              <svg fill="#000000" viewBox="0 0 24 24" id="vsco" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg"
                  className={showMenu ? "arcBtn" : ""}><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="secondary" d="M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm0,14a5,5,0,1,1,5-5A5,5,0,0,1,12,17Z" style={{ "fill": "#2ca9bc", "strokeWidth": "2" }}></path><path id="primary" d="M12,3V7m6.17-1.17L15.54,8.46m2.63,9.71-2.63-2.63M5.83,18.17l2.63-2.63M5.83,5.83,8.46,8.46M21,12H17m-5,9V17M3,12H7m10,0a5,5,0,1,0-5,5A5,5,0,0,0,17,12ZM12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Z"
                  style={{ "fill": "none", "stroke": "#000000", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2" }} ></path></g></svg>
              

      </div>
      <ul
      onClick={() => setShowMenu(!showMenu)}
       className={showMenu ? "showHeaderList" : "hideHeaderList"}>
        <li className={activeClass === "skills" ? "active" : ""}>
          <Link to="skills">Skills</Link>
        </li>
        <li className={activeClass === "projects" ? "active" : ""}>
          <Link to="projects">Projects</Link>
        </li>
        <li className={activeClass === "languages" ? "active" : ""}>
          <Link to="languages">Languages</Link>
        </li>
        <li className={activeClass === "contact" ? "active" : ""}>
          <Link to="contact">Contact</Link>
        </li>
        <li
          className={
            activeClass === "login" || activeClass === "admin" ? "active" : ""
          }
        >
          <Link to={loggedIn ? "admin" : "login"}>
            {loggedIn ? "Admin" : "Log in"}
          </Link>
        </li>
      </ul>
    </div>
  );
}
