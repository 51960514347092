import { useState, useEffect } from "react";
import AddOrEditProject from "./AddOrEditProject";

import Stack from "./Stack";
import { Project as ProjectInterface } from "../tools/interfaces";
import { Link } from "react-router-dom";
import Chapter from "./Chapter";
import ProjectDetails from "./ProjectDetails";


export default function Projects({ loggedIn }: { loggedIn: boolean | null }) {
    const [projects, setProjects] = useState<[ProjectInterface] | null>(null);
    const [currentProject, setCurrentProject] = useState<ProjectInterface | null>(null);
    const [editedProjectId, setEditedProjectId] = useState<string | null>(null);

    const fetchProjects = async () => {
        const response = await fetch("https://zakharii.com/api_endpoints/projects/", {
            method: "GET",
        });
        const data = await response.json();
        setProjects(data.reverse());
    };

    useEffect(() => {
        fetchProjects();
    }, []);


    const ADD_PROJECT = !loggedIn ? null : (
        <AddOrEditProject
            editedProject={null}
            switchMode={(id: null | string) => setEditedProjectId(id)}
            updateList={fetchProjects}
        />
    );

    const PROJECT_DETAILS = currentProject === null
        ? null : <ProjectDetails project={currentProject} hide={() => { setCurrentProject(null) }} />;

    return (
        <div className="Projects">
            <div className="projectsHeader">
                <Chapter header="Projects" />
                {PROJECT_DETAILS}
                {ADD_PROJECT}
            </div>
            <div className="projectsBody">
                {!projects ? (
                    <div>Loading...</div>
                ) : (
                    projects.map((project) => {

                        const EDIT_PROJECT = !loggedIn ? null : (
                            <AddOrEditProject
                                editedProject={project}
                                switchMode={(id: null | string) => setEditedProjectId(id)}
                                updateList={fetchProjects}
                            />
                        );

                        const PROJECT_VIEW = editedProjectId === project.id ? null : (
                            <div onClick={() => setCurrentProject(project)}>
                                <div className="projectLink">
                                    <h3>{project.name}</h3>
                                    <img className="projectIcon"
                                        src={
                                            "data:image;base64," +
                                            project.icon
                                        }
                                    />
                                </div>
                            </div>
                        );

                        return (
                            <div key={project.id} className="projectContainer">
                                {EDIT_PROJECT}
                                {PROJECT_VIEW}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}
