import Chapter from "./Chapter";
import { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
export default function Contact() {
  const [msg, setMsg] = useState({
    name: "",
    email: "",
    text: "",
  });

  const msgForm = new FormData();

  useEffect(() => {
    msgForm.append("name", msg.name);
    msgForm.append("email", msg.email);
    msgForm.append("message", msg.text);
  }, [msg]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetch("https://zakharii.com/api_endpoints/contact/", {
      method: "POST",
      body: msgForm,
    }).then((data) => {
      data.json().then((res) => console.log(res));
      if (data.status === 200) {
        alert("Your message has been sent!");
      }
    });
  };

  return (
    <div className="Contact">
      <Chapter header="Get in touch" />
      <div className="myContacts">
        <span className="emailContainer">
          <MdEmail />
          <h3>Email </h3>
          <h3 className="myEmail">zakhariihusar@gmail.com</h3>
        </span>
        <span className="phoneContainer">
          <BsFillTelephoneForwardFill />
          <h3>Phone </h3>
          <h3 className="myPhone">+1(204)890-6527</h3>
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="allFields">
          <div className="inputs">
            <input
              placeholder="Name"
              className="nameInput"
              onChange={(e) => setMsg({ ...msg, name: e.target.value })}
              value={msg.name}
            />
            <input
              placeholder="Email"
              className="emailInput"
              onChange={(e) => setMsg({ ...msg, email: e.target.value })}
              value={msg.email}
              type="email"
            />
            <input className="sendBtn" type="submit" value="Send" />
          </div>
            <textarea
              placeholder="Message"
              className="messageInput"
              onChange={(e) => setMsg({ ...msg, text: e.target.value })}
              value={msg.text}
            />
        </div>
        <div className="contactIcons">
          <a href="https://github.com/Zakharii-Husar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6c-3.313 0-6 2.686-6 6 0 2.651 1.719 4.9 4.104 5.693.3.056.396-.13.396-.289v-1.117c-1.669.363-2.017-.707-2.017-.707-.272-.693-.666-.878-.666-.878-.544-.373.041-.365.041-.365.603.042.92.619.92.619.535.917 1.403.652 1.746.499.054-.388.209-.652.381-.802-1.333-.152-2.733-.667-2.733-2.965 0-.655.234-1.19.618-1.61-.062-.153-.268-.764.058-1.59 0 0 .504-.161 1.65.615.479-.133.992-.199 1.502-.202.51.002 1.023.069 1.503.202 1.146-.776 1.648-.615 1.648-.615.327.826.121 1.437.06 1.588.385.42.617.955.617 1.61 0 2.305-1.404 2.812-2.74 2.96.216.186.412.551.412 1.111v1.646c0 .16.096.347.4.288 2.383-.793 4.1-3.041 4.1-5.691 0-3.314-2.687-6-6-6z" />
            </svg>
          </a>
          <a href="https://my.facebook.com/people/Zakharii-Husar/100027181080635/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
            </svg>
          </a>
          <a href="https://www.instagram.com/zakharii_husar/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z" />
            </svg>
          </a>
        </div>
      </form>
    </div>
  );
}
