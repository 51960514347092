import { useState, useEffect } from "react";
import { Skill as SkillInterface } from "../tools/interfaces";
import DeleteItem from "./DeleteItem";
import { TiEdit, TiCancel } from "react-icons/ti";
import { CgAdd } from "react-icons/cg";

const skillDefaultState = {
  name: "",
  id: "0",
  level: "50",
  icon: null,
};

export default function AddOrEditSkill({
  editedSkill,
  switchMode,
  updateList,
}: {
  editedSkill: SkillInterface | null;
  switchMode: (id: null | string) => void;
  updateList: () => void;
}) {
  const [showForm, setShowForm] = useState(false);
  const [skill, setSkill] = useState<SkillInterface>(
    editedSkill || skillDefaultState
  );

  const skillForm = new FormData();

  useEffect(() => {
    skillForm.append("id", skill.id);
    skillForm.append("name", skill.name);
    skillForm.append("level", skill.level);
    if (skill.icon) skillForm.append("icon", skill.icon);
  }, [skill]);

  useEffect(() => {
    showForm ? switchMode(skill.id) : switchMode(null);
  }, [showForm]);

  const validateInput = () => {
    if (
      Number(skill.level) > 10 &&
      skill.name.length > 2 &&
      skill.icon !== null
    )
      return true;
    alert("Incorrect input!");
    return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validateInput()) return;

    fetch("https://zakharii.com/api_endpoints/skills/", {
      method: "POST",
      body: skillForm,
      credentials: "include",
    }).then((data) => {
      if (data.status === 200) {
        if (!editedSkill) setSkill(skillDefaultState);
        updateList();
        setShowForm(false);
      }
    });
  };

  const handleImgUpload = (e: any) => {
    setSkill({ ...skill, icon: e.target.files[0] });
  };

  return (
    <div className="AddOrEditSkill">
      <div className={editedSkill ? "editSkillPanel" : "addSkillPanel"}>
        {showForm ? (
          <TiCancel onClick={() => setShowForm(!showForm)} />
        ) : !editedSkill ? (
          <CgAdd onClick={() => setShowForm(!showForm)} />
        ) : (
          <TiEdit onClick={() => setShowForm(!showForm)} />
        )}
        {!editedSkill ? null : (
          <DeleteItem  id={editedSkill.id} category="skills" updateList={updateList} />
        )}
      </div>

      {!showForm ? null : (
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <label htmlFor="skillName">Name:</label>

          <input
            id="skillName"
            name="name"
            value={skill.name}
            onChange={(e) =>
              setSkill({ ...skill, name: e.target.value.toString() })
            }
          />

          <label htmlFor="skillLevel">Level:</label>

          <input
            id="skillLevel"
            type="range"
            value={skill.level}
            onChange={(e) =>
              setSkill({ ...skill, level: e.target.value.toString() })
            }
            name="level"
          />

          <label htmlFor="skillIconInput">Icon:</label>
          <input
            id="skillIconInput"
            type="file"
            accept="image/jpeg, image/png, image/jpg, image/gif"
            onChange={(e) => handleImgUpload(e)}
          />

          {!skill.icon ? null : (
            <img
              id="skillIcon"
              src={
                typeof skill.icon === "string"
                  ? "data:image;base64," + skill.icon
                  : URL.createObjectURL(skill.icon)
              }
            />
          )}
          <input
            type="submit"
            value={!editedSkill ? "Add skill" : "Update Skill"}
          />
        </form>
      )}
    </div>
  );
}
