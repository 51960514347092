import { useParams } from "react-router-dom";

export default function Project() {
    const { project } = useParams();

    const projectsDir = project === "blazor_todo" ?
        "https://zakharii.com/projects/" :
        "https://zakharii.com/git_projects/";

  return <iframe
      style={{backgroundColor:"white"}}
     
      src={`${projectsDir}/${project}`}
      title="My Page"
      width="100%"
      height="100%"
    />
}
