import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useState } from "react";
import Contact from "./components/Contact";
import Tracker from "./components/Tracker";
import Languages from "./components/Languages";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import LoginPage from "./components/LoginPage";
import Admin from "./components/Admin";
import HomePage from "./components/HomePage";
import Header from "./components/Header";
import Project from "./components/Project";
import ProtectedRoutes from "./tools/ProtectedRoutes";
import { Authorization } from "./tools/Authorization";
import NotFound from "./components/NotFound";

function App() {
  const [loggedIn, setLoggedIn] = useState<null | boolean>(null);

  const updateLogin = (status: boolean) => {
    setLoggedIn(status);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Header loggedIn={loggedIn} />
         <Tracker  loggedIn={loggedIn}/>
        <Authorization updateLogin={updateLogin} />

        <Routes>
          <Route element={<ProtectedRoutes loggedIn={loggedIn} />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/skills" element={<Skills loggedIn={loggedIn} />} />
          <Route path="/languages" element={<Languages />} />

          <Route path="/projects" element={<Projects loggedIn={loggedIn} />} />
          <Route path="/projects/:project" element={<Project/>}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<LoginPage loggedIn={loggedIn} />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>
      </BrowserRouter>
  );
}

export default App;
