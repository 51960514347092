import { useState, useEffect } from "react";
import { Skill as SkillInterface } from "../tools/interfaces";
import Chapter from "./Chapter";

import AddOrEditSkill from "./AddOrEditSkill";

export default function Skills({ loggedIn }: { loggedIn: boolean | null }) {
  const [skills, setSkills] = useState<null | [SkillInterface]>(null);
  const [editedSkillId, setEditedSkillId] = useState<null | string>(null);
  const [skillPercent, setSkillPercent] = useState(0);

  async function fetchSkills() {
    try {
      const response = await fetch("https://zakharii.com/api_endpoints/skills/");
      const data = await response.json();
      setSkills(data.reverse());
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    fetchSkills();
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (skillPercent < 99) setSkillPercent(skillPercent + 1);
    }, 40);

    return () => clearInterval(interval);
  }, [skillPercent]);

  return (
    <div className="Skills">
      <div className="skillsHeader">
        <Chapter header="Skills"/>
        {loggedIn ? (
          <AddOrEditSkill
            editedSkill={null}
            switchMode={(id: null | string) => setEditedSkillId(id)}
            updateList={fetchSkills}
          />
        ) : null}
      </div>

      <div className="skillsBody">
        {!skills ? (
          <div>
            <div>Loading...</div>
          </div>
        ) : (
          skills.map((skill) => {
            return (
              <div key={skill.id} className="skill">
                {!loggedIn ? null : (
                  <AddOrEditSkill
                    editedSkill={skill}
                    switchMode={(id: null | string) => setEditedSkillId(id)}
                    updateList={fetchSkills}
                  />
                )}

                {skill.id === editedSkillId ? null : (
                  <ul>
                    <li className="skillName">{skill.name}</li>
                    <li
                      className={
                        skill.name === "React JS" ? "reactIcon" : "skillIcon"
                      }
                    >
                      <img
                        src={
                          "data:image;base64," +
                          skill.icon
                        }
                        alt=""
                      />
                    </li>
                    <li className="skillLvlBorder">
                      <span style={{ width: `${skill.level}%` }}
                      className="skillScale">
                        {`${
                          skillPercent < Number(skill.level)
                            ? skillPercent
                            : skill.level
                        }%`}
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
