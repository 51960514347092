import { useState, useEffect } from "react";
import { Project as ProjectInterface } from "../tools/interfaces";
import Stack from "./Stack";
import DeleteItem from "./DeleteItem";
import { TiEdit, TiCancel } from "react-icons/ti";
import { CgAdd } from "react-icons/cg";

const projectDefaultState = {
  id: "0",
  name: "",
  icon: null,
  link: "",
  code: "",
  public: true,
  stack: [],
  description: "",
};

export default function AddOrEditProject({
  editedProject,
  switchMode,
  updateList,
}: {
  editedProject: ProjectInterface | null;
  switchMode: (id: null | string) => void;
  updateList: () => void;
}) {
  const [project, setProject] = useState<ProjectInterface>(
    editedProject || projectDefaultState
  );
  const [showForm, setShowForm] = useState(false);
  const projectForm = new FormData();

  useEffect(() => {
    projectForm.append("id", project.id.toString());
    projectForm.append("name", project.name);
    if (project.icon) projectForm.append("icon", project.icon);
    projectForm.append("link", project.link);
    projectForm.append("code", project.code);
    if(project.public !== undefined)projectForm.append("public", project?.public.toString());
    projectForm.append("stack", JSON.stringify(project.stack));
    projectForm.append("description", project.description);
  }, [project]);

  useEffect(() => {
    showForm ? switchMode(project.id) : switchMode(null);
  }, [showForm]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    fetch("https://zakharii.com/api_endpoints/projects/", {
      method: "POST",
      body: projectForm,
      credentials: "include",
    }).then((data) => {
      data.json().then(res=>console.log(res))
      if (data.status === 200) {
        if (!editedProject) setProject(projectDefaultState);
        setShowForm(false);
        updateList();
      }
    });
  };

  const handleImgUpload = (e: any) => {
    setProject({ ...project, icon: e.target.files[0] });
  };

  return (
    <div className="AddOrEditProject">
      <div className="editProjectPanel">
          {showForm
            ? <TiCancel onClick={() => setShowForm(!showForm)}/>
            : !editedProject
            ? <CgAdd onClick={() => setShowForm(!showForm)}/>
            : <TiEdit onClick={() => setShowForm(!showForm)}/>}
        {!editedProject ? null : (
          <DeleteItem  id={editedProject.id} category="projects" updateList={updateList} />
        )}
      </div>

      {showForm ? (
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <label htmlFor="InputProjectName">Project Name:</label>
          <br />

          <input
            id="InputProjectName"
            name="name"
            value={project.name}
            onChange={(e) =>
              setProject({ ...project, name: e.target.value.toString() })
            }
          />

          <label htmlFor="inputProjectIcon">Project Icon</label>
          <br />

          <input
            id="inputProjectIcon"
            type="file"
            accept="image/jpeg, image/png, image/jpg, image/gif"
            onChange={(e) => handleImgUpload(e)}
          />

          <label htmlFor="inputProjectLink">Project Link</label>
          <br />

          <input
            id="inputProjectLink"
            name="link"
            value={project.link}
            onChange={(e) =>
              setProject({ ...project, link: e.target.value.toString() })
            }
          />

          <label htmlFor="inputProjectCode">Project Code</label>
          <br />

          <input
            id="inputProjectCode"
            name="code"
            value={project.code}
            onChange={(e) =>
              setProject({ ...project, code: e.target.value.toString() })
            }
          />

<span><label htmlFor="checkBox">Public</label><input onChange={()=>setProject({ ...project, public: !project.public })} checked={project.public} id="checkBox" name="public" type="checkbox" /></span>
          <label htmlFor="inputProjectLink">Project Description</label>
          <br />
          <input
            id="inputProjectDescription"
            name="description"
            value={project.description}
            onChange={(e) =>
              setProject({ ...project, description: e.target.value.toString() })
            }
          />

          <label htmlFor="inputProjectStack">Project Stack</label>
          <br />
          <Stack
            loggedIn={true}
            skillsUsed={project.stack}
            updateStack={(skill: string[]) =>
              setProject({ ...project, stack: skill })
            }
          />

          <input
            type="submit"
            value={editedProject ? "Update Project" : "Add Project"}
          />
          {!project.icon ? null : (
            <img
              id="skillIcon"
              src={
                typeof project.icon === "string"
                  ? "data:image;base64," + project.icon
                  : URL.createObjectURL(project.icon)
              }
            />
          )}
        </form>
      ) : null}
    </div>
  );
}
