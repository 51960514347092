import { Project as ProjectInterface } from "../tools/interfaces";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { TbSourceCode } from "react-icons/tb";
import { CiLink } from "react-icons/ci";
import Stack from "./Stack";
import Chapter from "./Chapter";


export default function ProjectDetails({ project, hide }: { project: ProjectInterface, hide: () => void }) {
    return (project === null ? null :
        <div className="ProjectDetails">
            <div className="hideDetails" onClick={hide}>
                <RxCross2 size={45} />
            </div>
            <Chapter header={project.name} />

            <div className="detailsBody">
                <div>
                    <img
                        className="projectDetailsImg"
                        src={
                            "data:image;base64," +
                            project.icon
                        }
                    />
                </div>
                <div className="detailsText">
                    <span className="detailsTextHead">
                        <Link to={project.link}>Link<CiLink /></Link>

                        {project.code === "private" ?
                            (<div>Private</div>) :
                            <a href={project.code}>Source Code <TbSourceCode /></a>}
                    </span>

                    <div className="detailsDescription">{project.description}</div>
                </div>
            </div>

            <Stack
                loggedIn={false}
                skillsUsed={project.stack}
                updateStack={null}
            />
        </div>
    )
}