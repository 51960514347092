import { useState, useEffect } from "react";
import { LoginAttempt as LoginAttemptInterface } from "../tools/interfaces";
import Chapter from "./Chapter";

export default function LoginAttempts() {
  const [attempts, setAttempts] = useState<LoginAttemptInterface[] | null>(
    null
  );

  const getLoginAttempts = async () => {
    const request = await fetch("https://zakharii.com/api_endpoints/get_info/", {
      method: "GET",
      credentials: "include",
    });
    const result = await request.json();
    console.log(result);
    setAttempts(result?.login_attempts?.reverse());
  };

  useEffect(() => {
    getLoginAttempts();
  }, []);

  return (
    <div>
      <Chapter header="Login Attempts"/>
      {!attempts
        ? null
        : attempts.map((attempt) => {
            return (
                <ul key={attempt.id}>
                  <li>{attempt.name}</li>
                  <li>{attempt.password}</li>
                  <li>{attempt.timestamp}</li>
                </ul>
            );
          })}
    </div>
  );
}
