import { useEffect, useCallback } from "react";

export const Authorization = ({
    updateLogin,
}: {
    updateLogin: (status: boolean) => void;
}) => {
    const logIn = useCallback(async () => {
        const checkAuthorization = await fetch(
            "https://zakharii.com/api_endpoints/authorization/",
            {
                method: "GET",
                credentials: "include"
            }
        );
        const decode = await checkAuthorization.json();
        updateLogin(decode);
    }, [updateLogin]);

    useEffect(() => {
        logIn();
    }, [logIn]);

    return null;
};
